<template>
  <div></div>
</template>

<script>
export default {
  name: 'Autologin',

  methods: {
    async autologin () {
      const email = this.$route.query.email
      const token = this.$route.query.token

      let user = await this.$store.dispatch('auth/autologin', {
        email,
        token
      })
      if (user) {
        this.$notify({
          title: this.$t('Acción realizada correctamente'),
          text: this.$t('Login correcto')
        })
        // Contamos las citas pendientes
        let reservas_pendientes = 0
        user.reservas_usuario_portal.forEach(
            current_reserva => {
              if (current_reserva.estado.id === 2) {
                ++reservas_pendientes
              }
            }
        )
        if (reservas_pendientes > 0) {
          const resp = await this.$alert(
              this.$t('Para poder realizar una nueva reserva, primero tienes que anular las citas pendientes.'),
              '',
              'info'
          )
          await this.$router.replace({ name: 'reservas' })
        } else {
          setTimeout(() => {
            this.$router.push({ name: 'centros' })
          }, 1500)
        }
      } else {
        this.$notify({
          title: this.$t('Error'),
          text: this.$t('Login incorrecto'),
          style: 'error'
        })
      }
    }
  },

  mounted() {
    this.autologin()
  }
}
</script>
